/* import __COLOCATED_TEMPLATE__ from './clone-repository-step.hbs'; */
import Component from '@glimmer/component';
import type RepositoryModel from 'codecrafters-frontend/models/repository';

export interface Signature {
  Element: HTMLDivElement;

  Args: {
    repository: RepositoryModel;
    isComplete: boolean;
  };
}

export default class CloneRepositoryStepComponent extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CoursePage::SetupStep::RepositorySetupCard::CloneRepositoryStep': typeof CloneRepositoryStepComponent;
  }
}
