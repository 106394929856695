
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("codecrafters-frontend/templates/course", function(){ return i("codecrafters-frontend/templates/course.hbs");});
d("codecrafters-frontend/controllers/course", function(){ return i("codecrafters-frontend/controllers/course.ts");});
d("codecrafters-frontend/routes/course", function(){ return i("codecrafters-frontend/routes/course.ts");});
d("codecrafters-frontend/templates/course/base-stages-completed", function(){ return i("codecrafters-frontend/templates/course/base-stages-completed.hbs");});
d("codecrafters-frontend/controllers/course/base-stages-completed", function(){ return i("codecrafters-frontend/controllers/course/base-stages-completed.ts");});
d("codecrafters-frontend/routes/course/base-stages-completed", function(){ return i("codecrafters-frontend/routes/course/base-stages-completed.ts");});
d("codecrafters-frontend/templates/course/completed", function(){ return i("codecrafters-frontend/templates/course/completed.hbs");});
d("codecrafters-frontend/controllers/course/completed", function(){ return i("codecrafters-frontend/controllers/course/completed.js");});
d("codecrafters-frontend/templates/course/extension-completed", function(){ return i("codecrafters-frontend/templates/course/extension-completed.hbs");});
d("codecrafters-frontend/controllers/course/extension-completed", function(){ return i("codecrafters-frontend/controllers/course/extension-completed.ts");});
d("codecrafters-frontend/routes/course/extension-completed", function(){ return i("codecrafters-frontend/routes/course/extension-completed.ts");});
d("codecrafters-frontend/templates/course/introduction", function(){ return i("codecrafters-frontend/templates/course/introduction.hbs");});
d("codecrafters-frontend/controllers/course/introduction", function(){ return i("codecrafters-frontend/controllers/course/introduction.ts");});
d("codecrafters-frontend/templates/course/setup", function(){ return i("codecrafters-frontend/templates/course/setup.hbs");});
d("codecrafters-frontend/controllers/course/setup", function(){ return i("codecrafters-frontend/controllers/course/setup.ts");});
d("codecrafters-frontend/routes/course/setup", function(){ return i("codecrafters-frontend/routes/course/setup.ts");});
d("codecrafters-frontend/routes/course/stage", function(){ return i("codecrafters-frontend/routes/course/stage.js");});
d("codecrafters-frontend/templates/course/stage/code-examples", function(){ return i("codecrafters-frontend/templates/course/stage/code-examples.hbs");});
d("codecrafters-frontend/controllers/course/stage/code-examples", function(){ return i("codecrafters-frontend/controllers/course/stage/code-examples.ts");});
d("codecrafters-frontend/templates/course/stage/instructions", function(){ return i("codecrafters-frontend/templates/course/stage/instructions.hbs");});
d("codecrafters-frontend/controllers/course/stage/instructions", function(){ return i("codecrafters-frontend/controllers/course/stage/instructions.ts");});
d("codecrafters-frontend/templates/course/stage/screencasts", function(){ return i("codecrafters-frontend/templates/course/stage/screencasts.hbs");});
d("codecrafters-frontend/controllers/course/stage/screencasts", function(){ return i("codecrafters-frontend/controllers/course/stage/screencasts.ts");});
d("codecrafters-frontend/templates/course/stage/concepts", function(){ return i("codecrafters-frontend/templates/course/stage/concepts.hbs");});
d("codecrafters-frontend/routes/course/stage/concepts", function(){ return i("codecrafters-frontend/routes/course/stage/concepts.js");});
